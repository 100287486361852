import React, { useState, useEffect } from "react";
import config from "./utils/config";
import axios from "axios";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";
import LoaderComponent from "./LoaderComponent";
import { toast } from "react-toastify";
import _ from "lodash";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const ClaimForm = (props) => {
  const [labelNames, setlabelNames] = useState(config.englishText);
  const [orderDate, setorderDate] = useState(props.clickDate);
  const [orderNumber, setorderNumber] = useState(null);
  const [orderValue, setorderValue] = useState(null);
  const [commisionValue, setcommisionValue] = useState(null);
  const { userId, partnerId } = useParams();
  const [fileData, setfileData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const supportedFormats = ["jpg", "png", "jpeg", "pdf", "doc", "docx"];

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [showErrorMsg, setshowErrorMsg] = useState(false);
  const [showDateErrorMsg, setshowDateErrorMsg] = useState(false);
  const [showOrderValueErrorMsg, setshowOrderValueErrorMsg] = useState(false);
  const [showCommissionErrorMsg, setshowCommissionErrorMsg] = useState(false);
  const [showCurrencyErrorMsg, setshowCurrencyErrorMsg] = useState(false);
  const [showFileDataErrorMsg, setShowFileDataErrorMsg] = useState(false);
  const [showFileRequiredErrorMsg, setShowFileRequiredErrorMsg] = useState(false);
  const [showFileSizeExceedErrorMsg, setShowFileSizeExceedErrorMsg] = useState(false);

  const [expectedCommissionData, setexpectedCommissionData] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [rewardCurrencyCode, setrewardCurrencyCode] = useState("");

  const currencyCodesData = [
    { value: "SEK", label: "SEK" },
    { value: "NOK", label: "NOK" },
    { value: "GBP", label: "GBP" },
    { value: "EUR", label: "EUR" },
    { value: "DKK", label: "DKK" },
  ];

  const navigate = useNavigate();

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
    setshowCurrencyErrorMsg(false);
  };

  useEffect(() => {
    if (config.swedish_partner.includes(props.partnerId)) {
      setlabelNames(config.swedishText);
    } else if (config.danish_partner.includes(props.partnerId)) {
      setlabelNames(config.danishText);
    } else if (config.norweign_partner.includes(props.partnerId)) {
      setlabelNames(config.norwegianText);
    } else if (config.swedish_remember_partner.includes(props.partnerId)) {
      setlabelNames(config.swedish_remember_partner_text);
    } else if (config.norweign_remember_partner.includes(props.partnerId)){
      setlabelNames(config.norweign_remember_partner_text);
    } else if (config.danish_remember_partner.includes(props.partnerId)){
      setlabelNames(config.danish_remember_partner_text);
    } else {
      setlabelNames(config.englishText);
    }
  });

  useEffect(() => {
    fetchCommissionData();
  }, []);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

  const handleFileChange = (event) => {
    setShowFileDataErrorMsg(false);
    setShowFileRequiredErrorMsg(false);
    setShowFileSizeExceedErrorMsg(false);
    if (event.target.type === "file") {
      const uploadedFile = event.target.files[0];
      if (uploadedFile) {
        if (uploadedFile.size > MAX_FILE_SIZE) {
          setfileData(null);
          setShowFileSizeExceedErrorMsg(true);
        }else{
          // console.log("uploadedFile: ", uploadedFile);
          let fileExtention = uploadedFile.name.split(".").pop().toLowerCase();
          // console.log(fileExtention);
          if (supportedFormats.includes(fileExtention)) {
            setfileData(uploadedFile);
            // console.log("setting data success: ", uploadedFile);
          } else {
            setfileData(null);
            // console.log("setting data failed: ", uploadedFile);
            setShowFileDataErrorMsg(true);
          }
        }
      }
    }
  };

  const notify = (message) =>
    toast.success(message, {
      toastId: "success1",
    });

  const handleSubmit = () => {
    const formattedDate = dayjs(orderDate).format("YYYY-MM-DD HH:mm:ss");
    // console.log("date----------", formattedDate);

    if (
      _.isEmpty(orderNumber) &&
      _.isEmpty(orderValue) &&
      _.isEmpty(selectedOption) &&
      _.isEmpty(selectedCurrency) &&
      (fileData == null) &&
      (fileData != null && fileData.size > MAX_FILE_SIZE)
    ) {
      setshowErrorMsg(true);
      setshowCommissionErrorMsg(true);
      setshowOrderValueErrorMsg(true);
      setshowCurrencyErrorMsg(true);
      setShowFileRequiredErrorMsg(true);
      setShowFileSizeExceedErrorMsg(true);
    } else if (_.isEmpty(orderNumber)) {
      setshowErrorMsg(true);
    } else if (formattedDate === "Invalid Date" || _.isEmpty(orderDate)) {
      setshowDateErrorMsg(true);
    } else if (_.isEmpty(orderValue)) {
      setshowOrderValueErrorMsg(true);
    } else if (_.isEmpty(selectedOption)) {
      setshowCommissionErrorMsg(true);
    } else if (_.isEmpty(selectedCurrency)) {
      setshowCurrencyErrorMsg(true);
    } else if (fileData == null){
      setShowFileRequiredErrorMsg(true);
    } else if (fileData != null && fileData.size > MAX_FILE_SIZE){
      setShowFileSizeExceedErrorMsg(true);
    } else {
      setisLoading(true);

      const formDataObj = new FormData();

      const userClaim = {
        claimedOrderNumber: orderNumber,
        userId: userId,
        claimedOrderDate: formattedDate,
        claimedShopName: props.shopName,
        partnerId: props.partnerId,
        orderValue: orderValue,
        // commisionValue: selectedOption.label,
        rewardCategory: _.isEmpty(selectedOption)
          ? ""
          : selectedOption.rewardCategory,
        rewardCommisionPercentage: _.isEmpty(selectedOption)
          ? ""
          : selectedOption.rewardCommisionPercentage,
        claimedUserEmail: props.email,
        clickId: props.activityId,
        affiliateNetwork: props.formData.affiliateNetwork,
        orderValueCurrencyCode: selectedCurrency.value,
        partnerName: props.formData.partnerName,
        rewardCurrencyCode: rewardCurrencyCode,
        affiliateId: props.formData.affiliateId,
        rewardCommissionType: _.isEmpty(selectedOption)
          ? ""
          : selectedOption.rewardCommissionType,
      };
      // console.log("&&&&&&&&&&&&", userClaim);

      formDataObj.append(
        "userClaim",
        new Blob([JSON.stringify(userClaim)], { type: "application/json" })
      );
      formDataObj.append("attachment", fileData);
      // console.log("-----------", formDataObj);

      axios
        .post(
          `${config.SERVER_URL}/saveuserclaim?attachment`,
          formDataObj,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          setisLoading(false);
          // console.log(response.data);
          // if (response.data.message) notify(labelNames["claim_registered"]);
          navigate(
            `/success/${encodeURIComponent(labelNames["claim_registered"])}/${partnerId}`
          );
        })
        .catch((error) => {
          setisLoading(false);
          if (error.response) {
            notify(error.response.data.message);
          } else {
            notify(error.message);
          }
        });
    }
  };

  const fetchCommissionData = () => {
    axios
      .get(
        `${config.SERVER_URL}/getrewardcategory?shopname=${props.shopName}&partnerId=${props.partnerId}`
      )
      .then((resp) => {
        if(resp.data.success === true){
          setexpectedCommissionData(
            resp.data.reward_commision.map(
              ([
                rewardCategory,
                rewardCommissionType,
                rewardCommisionPercentage,
              ]) => ({
                value: rewardCategory,
                label:
                  rewardCategory +
                  " - " +
                  rewardCommisionPercentage +
                  (rewardCommissionType === "PERCENTAGE" ? "%" : ""),
                rewardCommissionType: rewardCommissionType,
                rewardCategory: rewardCategory,
                rewardCommisionPercentage: rewardCommisionPercentage,
              })
            )
          );
          setrewardCurrencyCode(resp.data.shop_currency_code);
        }
      })
      .catch((error) => {
        navigate(`/apology/${userId}/${props.partnerId}`);
        console.error(error);
      });
  };

  return (
    <section
      id="form-total-p-0"
      role="tabpanel"
      aria-labelledby="form-total-h-0"
      className="wizard-content"
      aria-hidden="false"
    >
      <div className={config.gold_color_partners.includes(partnerId) ?"reward-support-form-item" :"row mb-3"}>
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Order Number"]}:
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            // className="form-control"
            id="orderNumber"
            onChange={(event) => {
              setshowErrorMsg(false);
              setorderNumber(event.target.value);
            }}
            value={orderNumber}
          />
          {showErrorMsg && (
            <p className="error-message">This field is required</p>
          )}
        </div>
      </div>
      <div className={config.gold_color_partners.includes(partnerId) ?"reward-support-form-item" :"row mb-3"}>
        <label htmlFor="colFormLabel" className={config.gold_color_partners.includes(partnerId) ? "col-sm-2 col-form-label w-100" :"col-sm-2 col-form-label w-sm-100"}>
          {labelNames["Order Value"]}:
        </label>
        <div className="col-sm-10" style={{ display: "flex" }}>
          <div className="col-sm-6">
            {" "}
            <input
              type="number"
              // className="form-control"
              id="orderValue"
              onChange={(event) => {
                setshowOrderValueErrorMsg(false);
                setorderValue(
                  event.target.value
                    .replace(/[^0-9.value]/g, "")
                    .replace(/(\..*)\./g, "$1")
                );
              }}
              value={orderValue}
            />
            {showOrderValueErrorMsg && (
              <p className="error-message">This field is required</p>
            )}
          </div>
          <div className="col-sm-6" style={{ paddingLeft: "10px" }}>
            {" "}
            <Select
              options={currencyCodesData}
              value={selectedCurrency}
              className={config.gold_color_partners.includes(partnerId) ? "gold-custom-select gold-form-control" : ""}
              onChange={(data) => {
                setshowCurrencyErrorMsg(false);
                setSelectedCurrency(data);
              }}
            />
            {showCurrencyErrorMsg && (
              <p className="error-message">Please choose a currency</p>
            )}
          </div>
        </div>
      </div>
      <div className={config.gold_color_partners.includes(partnerId) ?"reward-support-form-item" :"row mb-3"}>
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["Order Date"]}:
        </label>

        <div 
          className="col-sm-10"
          id={
            config.gold_color_partners.includes(partnerId)
              ? "input-border-none"
              : ""
          }
          >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                value={orderDate}
                onChange={(neworderDate) => {
                  setorderDate(neworderDate);
                }}
                ampm={false}
                format="YYYY-MM-DD HH:mm"
                id="orderDate"
                disabled={true}
                className="gold-form-control"
              />
              {showDateErrorMsg && (
                <p className="error-message">This field is required</p>
              )}
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      {!_.isEmpty(expectedCommissionData) && (
        <div className={config.gold_color_partners.includes(partnerId) ?"reward-support-form-item" :"row mb-3"}>
          <label htmlFor="colFormLabel" className={config.gold_color_partners.includes(partnerId) ? "col-sm-2 col-form-label w-100" :"col-sm-2 col-form-label w-sm-100"}>
            {labelNames["Expected Commission"]}:
          </label>
          <div className="col-sm-10">
            {/* <input
            type="text"
            // className="form-control"
            id="expectedCommission"
            onChange={(event) => setcommisionValue(event.target.value)}
            value={commisionValue}
            required
          /> */}
            <Select
              options={expectedCommissionData}
              value={selectedOption}
              className={config.gold_color_partners.includes(partnerId) ? "gold-custom-select gold-form-control" : ""}
              onChange={(data) => {
                setshowCommissionErrorMsg(false);
                setSelectedOption(data);
              }}
            />
            {showCommissionErrorMsg && (
              <p className="error-message">This field is required</p>
            )}
          </div>
        </div>
      )}
      <div className={config.gold_color_partners.includes(partnerId) ?"reward-support-form-item" :"row mb-3"}>
        <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
          {labelNames["File Upload"]}
        </label>
        <div className="col-sm-10">
          <input
            type="file"
            accept={supportedFormats.map((el) => "." + el)}
            // className="form-control"
            onChange={handleFileChange}
            className={config.gold_color_partners.includes(partnerId) ?"p-14 file-upload" :""}
          />{" "}
          <p style={{"font-size": "medium"}}>{`(${supportedFormats})`}</p>
          {showFileDataErrorMsg && (
            <p className="error-message">
              This file is not accepted! Please choose a file in the above
              formats
            </p>
          )}
          {showFileRequiredErrorMsg && (
            <p className="error-message">
              Attachment is required
            </p>
          )}
          {showFileSizeExceedErrorMsg && (
            <p className="error-message">
              File size exceeds the maximum limit of 5 MB.
            </p>
          )}
        </div>
      </div>

      <button 
        type="submit" 
        className={ config.gold_color_partners.includes(partnerId) ? "reward-support-form-item-button btn-submit gold-submit-btn" : "btn-submit" } 
        onClick={handleSubmit} 
        style={props.myBtnStyle}
      >
        {labelNames["Submit"]}
      </button>
      {isLoading && <LoaderComponent />}
    </section>
  );
};
export default ClaimForm;

